/*
  Index:
  1.0 - Brevity
  2.0 - Navigations
  3.0 - Header Search
  4.0 - Expanders
  5.0 - Accordions
  6.0 - Tabs
  7.0 - Hero carousels
  8.0 - Homepage Hero
  9.0 - Shortcode Carousels
  10.0 - Homepage Alert
  11.0 - Search Results Grid
  12.0 - Mega Menu
  13.0 - Dynamic Footer
  14.0 - Login links to the very top of the sidebar
  15.0 - Webinar Series Template
  16.0 - Splide slider
  17.0 - Search string and wrap in span
*/


/**
 * Main document.ready
 */

function getMainDomain() {
  var domainParts = window.location.hostname.split('.');
  var mainDomain;

  // If the domain has more than two parts, it's a subdomain
  if (domainParts.length > 2) {
      // Subdomain is the first part, join the rest for main domain
      mainDomain = 'www.' + domainParts.slice(1).join('.');
  } else {
      mainDomain = window.location.hostname;
  }

  return mainDomain;
}


// Function to wrap text
  function wrapText(element, textToWrap) {
      var regex = new RegExp('(' + textToWrap + ')', 'gi');
      element.html(function(_, html) {
          return html.replace(regex, '<span class="highlight">$1</span>');
      });
  }

// Fixed navbar
  document.addEventListener('scroll', function () {
    const menu = document.querySelector('body:not(.page-template-tpl-page-blank) nav.subdomain');
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    // Check if the menu element exists before proceeding
    if (!menu) {
      return; // If menu is null, exit the function
    }

    /*
    if (window.innerWidth >= 768) { // Only for devices above tablet size
      if (scrollTop > 500) {
        menu.classList.add('scrolled');
      } else {
        menu.classList.remove('scrolled');
      }
    }
    */
  });





jQuery(document).ready(function($) {

  /**
   * Brevity
   */

    brevity([
      { 'abbr':'AAC', 'title':'Augmentative and Alternative Communication' },
      { 'abbr':'ADA', 'title':'Americans with Disabilities Act' },
      { 'abbr':'AT', 'title':'Assistive Technology' },
      { 'abbr':'BCTAC', 'title':'Bridging Communities Through Alternative Communication' },
      { 'abbr':'FAF', 'title':'Framing A Future' },
      { 'abbr':'AAC/AT', 'title':'Augmentative and Alternative Communication/Assistive Technology' },
      { 'abbr':'CCN', 'title':'Complex Communication Needs' },
      { 'abbr':'EADL', 'title':'Electronic Aids for Daily Living' },
      { 'abbr':'E-tran', 'title':'Eye Transfer System' },
      { 'abbr':'IDEA', 'title':'Individuals with Disabilities Education (Improvement) Act' },
      { 'abbr':'IEP', 'title':'Individualized Education Plan' },
      { 'abbr':'ITP', 'title':'Individualized Transition Plan' },
      { 'abbr':'LVS', 'title':'Live Voice or Partner Assisted Scanning' },
      { 'abbr':'ISAAC', 'title':'International Society for Augmentative and Alternative Communication' },
      { 'abbr':'SGD', 'title':'Speech Generating Device' },
      { 'abbr':'USSAAC', 'title':'United States Society for Augmentative and Alternative Communication' },
      { 'abbr':'VOCA', 'title':'Voice Output Communication Aids' },
      { 'abbr':'SDP', 'title':'Self-Determination Program' },
      { 'abbr':'SSPI', 'title':'Severe Speech and Physical Impairments' },
      { 'abbr':'BBCTI', 'title':'Building Bridges Camp & Training Institute' }
    ]);


  /**
   * Navigations
   */

    bs_main_nav();
      bs_main_nav_tools();
    bs_subnav();


  /**
   * Header Search
   * TODO: deprecate in favor of CSS only solution
   */

  	bs_header_search();


  /**
   * Expanders
   */

    $('.bs-expand').each(function() {
      bs_expander($(this));
    });


  /**
   * Accordions
   */

    $('.bs-accordions').each(function() {
      bs_accordion($(this));
    });


  /**
   * Tabs
   */

    $('.bs-tabgroup').each(function() {
      bs_tabs($(this));
    });


  /**
   * Hero carousels
   */

    var hero_container = $('.hero');
    if(hero_container.length) {
      var hero_options = {
        prevhtml: '<i class="fa-solid fa-chevron-left"></i>',
        nexthtml: '<i class="fa-solid fa-chevron-right"></i>',
        nav: false
      }
      shyft(hero_container, hero_options);
    }


  /**
   * Homepage Hero
   */

    var homepage_hero_container = $('.homepage-hero');
    if(homepage_hero_container.length) {
      var hero_options = {
        prevhtml: '<i class="fa-solid fa-chevron-left"></i>',
        nexthtml: '<i class="fa-solid fa-chevron-right"></i>',
        nav: false,
        autoplay: true,
        delay: 8000
      }
      shyft(homepage_hero_container, hero_options);
    }


  /**
   * Shortcode Carousels
  */

    var shortcode_carousel = $('.bs-carousel');
    if(shortcode_carousel.length) {
      // var container = shortcode_carousel.parents('.bs-carousel-container');
      var carousel_options = {
        nexthtml: '<i class="fa-solid fa-chevron-left"></i>',
        prevhtml: '<i class="fa-solid fa-chevron-right"></i>'
      }
      shyft(shortcode_carousel, carousel_options);
    }


  /**
   * Homepage Alert
   */

    $('.alert-container').on('click', '.alert-close', function(e) {
      e.preventDefault();
      $(this).parents('.alert-container').slideUp();
    });


  // Search Results Grid

    // init Isotope
    var $grid = $('.search-results-container').isotope({
      itemSelector: '.search-result',
      sortBy: 'date',
      sortAscending: true,
      percentPosition: true,
      masonry: {
        // use outer width of grid-sizer for columnWidth
        columnWidth: '.grid-sizer'
      },
      getSortData: {
        name: '.name',
        symbol: '.symbol',
        number: '.number parseInt',
        category: '[data-category]',
        weight: function( itemElem ) {
          var weight = $( itemElem ).find('.weight').text();
          return parseFloat( weight.replace( /[\(\)]/g, '') );
        }
      }
    });

    // filter functions
    var filterFns = {
      // show if number is greater than 50
      numberGreaterThan50: function() {
        var number = $(this).find('.number').text();
        return parseInt( number, 10 ) > 50;
      },
      // show if name ends with -ium
      ium: function() {
        var name = $(this).find('.name').text();
        return name.match( /ium$/ );
      }
    };

    // bind filter button click
    $('#search-filters-by-site').on( 'click', 'button', function() {
      var filterValue = $( this ).attr('data-filter');
      // use filterFn if matches value
      filterValue = filterFns[ filterValue ] || filterValue;
      $grid.isotope({ filter: filterValue });
    });

    // bind sort button click
    $('#sorts').on( 'click', 'button', function() {
      var sortByValue = $(this).attr('data-sort-by');
      $grid.isotope({ sortBy: sortByValue });
    });

    // change is-checked class on buttons
    $('.button-group').each( function( i, buttonGroup ) {
      var $buttonGroup = $( buttonGroup );
      $buttonGroup.on( 'click', 'button', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $( this ).addClass('is-checked');
      });
    });



  // Mega Menu


  $(".mega-menu-1").appendTo(".menu-1");
  $(".mega-menu-2").appendTo(".menu-2");
  $(".mega-menu-3").appendTo(".menu-3");
  $(".mega-menu-4").appendTo(".menu-4");
  $(".mega-menu-5").appendTo(".menu-5");
  $(".mega-menu-6").appendTo(".menu-6");
  $(".mega-menu-last").appendTo(".last");

  // $(".mega-menu-last").appendTo(".last");

  $(".mega-menu-response-1").appendTo(".mega-menu-1");
  $(".mega-menu-response-2").appendTo(".mega-menu-2");
  $(".mega-menu-response-3").appendTo(".mega-menu-3");
  $(".mega-menu-response-4").appendTo(".mega-menu-4");
  $(".mega-menu-response-5").appendTo(".mega-menu-5");
  $(".mega-menu-response-6").appendTo(".mega-menu-6");
  $(".mega-menu-response-last").appendTo(".mega-menu-last");



  // Select the 'mega-menu-last' element
  const megaMenuLast = $(".mega-menu-last");

  // Get the main domain
  const mainDomain = getMainDomain();

  // Construct the URL for loading content dynamically including the protocol
  const protocol = window.location.protocol;
  const contentUrl = protocol + "//" + mainDomain + "/mega_menus/last/ .megamenu";

  // Load content into .mega-menu-last div
  megaMenuLast.load(contentUrl);

  // alert(getMainDomain());








  // Dynamic footer
    /*
    $(".print-dynamic-footer-main-site").load("/footer-main-site/ .main-footer");
    $(".print-dynamic-footer-cvi").load("/footer-cvi/ .main-footer");
    $(".print-dynamic-footer-communication").load("/footer-communication/ .main-footer");
    $(".print-dynamic-footer-selfdetermined").load("/footer-selfdetermined/ .main-footer");
    $(".print-dynamic-footer-curriculum").load("/footer-curriculum/ .main-footer");
    */



  // Dynamic store
    // $(".print-store").load("https://bridgeschool.myshopify.com/collections/concert-posters .collection__main");

  // Login links to the very top of the sidebar
    // $(".login").parent().prepend(".page-list-sidebar");
    // $(".login").prependTo("ul.page-list-sidebar");

  // Webinar Series Template

      // Check for hash value in URL

      var hash = window.location.hash.substr(1);
      var href = $('body.page-template-page-webinar .page-list-sidebar li a').each(function(){
          var href = $(this).attr('href');
          if(hash==href.substr(0,href.length-0)){
              var toLoad = hash+'./ #content';
              $('.loadContent').load(toLoad)
          }
      });

      $('body.page-template-page-webinar .page-list-sidebar li a').click(function(){
        var toLoad = $(this).attr('href')+'./ #content';
        $('.loadContent').fadeOut('slow',loadContent);
        $('#load').remove();
        $('.wrapper').append('<span id="load"> Loading... </span>');
        $('#load').fadeIn('normal');

        // window.location.hash = $(this).attr('href').substr(0,$(this).attr('href').length-0);

        pageurl = $(this).attr('href').substr(0,$(this).attr('href').length-0);
        if(pageurl!=window.location) {
            window.history.pushState({path: pageurl}, '', pageurl);
        }

        function loadContent() {
            $('.loadContent').load(toLoad,'',showNewContent())
        }
        function showNewContent() {
            $('.loadContent').fadeIn('normal',hideLoader());
        }
        function hideLoader() {
            $('#load').fadeOut('normal');
        }
        return false;
    });



    $('.wp-block-cover').each(function(){
      var $backgroundSpan = $(this).find('.wp-block-cover__background');
      if ($backgroundSpan.length > 0) {
          $backgroundSpan.addClass('wave');
      }
    });


    // Target all <p> elements within .main-footer and wrap the text
      $('.main-footer p').each(function() {
          wrapText($(this), 'makes a difference');
      });

});


function simulateClick() {
  const pathElement = document.querySelector('[aria-label="Communicative Competence"]');

  if (pathElement) {
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true
    });

    pathElement.dispatchEvent(clickEvent);
  } else {
    console.error('Path element with aria-label "Communicative Competence" not found.');
  }
}

// Delay the execution to ensure the DOM is loaded
  if (window.matchMedia("(min-width: 768px)").matches) {
    setTimeout(simulateClick, 500);
  }


document.addEventListener("DOMContentLoaded", function() {

  // Find the <div> element with class "wp-block-group__inner-container"
  const slideElement = document.querySelector('.slide-home');

  if (slideElement) {

    slideElement.classList.add('splide');

    // Find the <div> element with class "wp-block-group__inner-container"
    const divElement = document.querySelector('.slide-home .wp-block-group__inner-container');
    divElement.classList.add('splide__track');

    // Create a new <ul> element
    const ulElement = document.createElement('ul');
    ulElement.classList.add('splide__list');

    // Loop through all the child elements inside the <div>
    while (divElement.firstChild) {
      const childElement = divElement.firstChild;
      divElement.removeChild(childElement);

      // Check if the child element has the class "wp-block-group"
      if (childElement.classList && childElement.classList.contains('wp-block-group')) {
        // If it's a div with class "wp-block-group", convert it into an <li> element
        const liElement = document.createElement('li');
        liElement.classList.add('splide__slide'); // Add the class "splide__slide" to the <li>

        // Add the existing classes of the <div> element to the new <li> element
        for (const className of childElement.classList) {
          liElement.classList.add(className);
        }

        // Move all the child elements inside the original <div> into the new <li>
        while (childElement.firstChild) {
          const grandChildElement = childElement.firstChild;
          childElement.removeChild(grandChildElement);
          liElement.appendChild(grandChildElement);
        }

        // Add the new <li> to the <ul>
        ulElement.appendChild(liElement);
      } else {
        // For other child elements, just move them into the <ul> as they are
        ulElement.appendChild(childElement);
      }
    }
    // Add the <ul> element back to the <div>
    divElement.appendChild(ulElement);

    // Running slider
      var splide = new Splide( '.splide',  {
        type: 'loop',
        perPage: 1,
        pagination: true,
        cover: true,
      });
      splide.mount();

  } else {
    console.log('Slider not found');
  }




      // Function to darken a color by a given percentage
        function darkenColor(rgb, percent) {
            // Extract the RGB values
            const rgbValues = rgb.match(/\d+/g).map(Number);
            const factor = 1 - percent / 100;

            // Darken each RGB value
            const darkenedRgb = rgbValues.map(value => Math.max(0, Math.min(255, Math.floor(value * factor))));

            // Return the new RGB color as a string
            return `rgb(${darkenedRgb.join(", ")})`;
        }

      // Get all elements with the class 'myDiv'
        const divs = document.querySelectorAll('.alert.info, .research');

      // Loop through each div and set the darkened border color
        divs.forEach(div => {
            const bgColor = window.getComputedStyle(div).backgroundColor;
            const darkenedColor = darkenColor(bgColor, 30); // Darken the color by 40%
            div.style.borderColor = darkenedColor;
        });




  function replaceClassForTablets() {
    if (window.matchMedia("(max-width: 768px)").matches) {

      const elements = document.querySelectorAll(".layout-right");
      elements.forEach(element => {
        element.classList.replace("layout-right", "layout-lightbox");
      });

      const dataElements = document.querySelectorAll('div[data-layout="right"]');
      dataElements.forEach(element => {
        // Change the data-layout attribute value
        element.setAttribute("data-layout", "lightbox");
      });

      handleHotspotInfo();

    }

  }

  function handleHotspotInfo() {
    const hotspotInfoDivs = document.querySelectorAll(".hotspot-info");
    const hotspotAreas = document.querySelectorAll(".more-info-area");

    hotspotAreas.forEach(area => {
      area.addEventListener("click", (event) => {
        event.preventDefault(); // Prevent default anchor click behavior

        const targetId = area.getAttribute("href").substring(1);
        const targetDiv = document.getElementById(targetId);

        hotspotInfoDivs.forEach(div => {
            div.classList.add("da-hidden");  // Hide all hotspot info divs
            div.classList.remove("visible"); // Remove visible class from all
        });

        targetDiv.classList.remove("da-hidden"); // Show the target hotspot info div
        targetDiv.classList.add("visible");      // Add visible class to target
      });
    });
  }

  // Check on initial load
  replaceClassForTablets();

  // Check on resize to handle responsive changes
  window.addEventListener("resize", replaceClassForTablets);



  // Search string and wrap in span

  const selectors = ['h2', 'p']; // Adding my selectors
  const phrases = ['innovative organization', 'another phrase']; // Desired phrases we're looking for
  const classes = ['highlight', 'another-class']; // CSS class we are injecting

  selectors.forEach((selector, index) => {
    document.querySelectorAll(selector).forEach((element) => {
      phrases.forEach((phrase, phraseIndex) => {
        if (element.textContent.includes(phrase)) {
          element.innerHTML = element.innerHTML.replace(
            new RegExp(`(${phrase})`, 'g'),
            `<span class="${classes[phraseIndex]}">$1</span>`
          );
        }
      });
    });
  });






  const scrollMessage = document.querySelector('.popup');
  const halfPage = document.documentElement.scrollHeight / 3;
  const hideAfter = halfPage * 1.5;

  scrollMessage.classList.add('fade-out');

  window.addEventListener('scroll', function () {
      const scrollPosition = window.scrollY;

      // Show the message when scrolling into the "band"
      if (scrollPosition >= halfPage && scrollPosition <= halfPage + hideAfter) {
          if (!scrollMessage.classList.contains('fade-in')) {
              scrollMessage.classList.remove('fade-out');
              scrollMessage.classList.add('fade-in');
          }
      }

      // Fade out when scrolling out of the "band" (both when scrolling down and up)
      if (scrollPosition < halfPage || scrollPosition > halfPage + hideAfter) {
          if (!scrollMessage.classList.contains('fade-out')) {
              scrollMessage.classList.remove('fade-in');
              scrollMessage.classList.add('fade-out');
          }
      }
  });




});
